import PropTypes from 'prop-types';

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
export const GREEN_THEME = 'green';
export const LIGHT_BLUE_THENE = 'lightBlue';

export default PropTypes.oneOf([
  LIGHT_THEME,
  DARK_THEME,
  GREEN_THEME,
  LIGHT_BLUE_THENE
]);

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useThemeFlag } from '../../../base/ThemeProvider';

import styles from './Link.module.scss';

const themes = {
  dark: 'link__dark',
  light: 'link__light',
  light_underline: 'link__light--underline',
  beige: 'link__beige'
};

const types = {
  primary: 'link__primary',
  secondary: 'link__secondary'
};

const sizes = {
  small: 'link-rebranded__small',
  medium: 'link-rebranded__medium',
  large: 'link-rebranded__large'
};

const cx = classNames.bind(styles);

const generateClassNameByProp = (prop, cn) => {
  const current = cn[prop];
  return styles[current] || '';
};

const generateClassName = (theme, hover, type) => {
  const className = styles.link;
  const themeClassName = generateClassNameByProp(theme, themes);
  const typeClassName = generateClassNameByProp(type, types);

  return cx(className, themeClassName, typeClassName, {
    [styles.link__action]: hover
  });
};

const generateLinkClassName = (hover, size, disabled, underlined, bold) => {
  const className = styles['link-rebranded'];
  const sizeClassName = generateClassNameByProp(size, sizes);
  return cx(className, sizeClassName, {
    [styles['link-rebranded__action']]: hover,
    [styles['link-rebranded__disabled']]: disabled,
    [styles['link-rebranded__underlined']]: underlined,
    [styles['link-rebranded__bold']]: bold
  });
};

const Link = ({
  children = undefined,
  theme = 'default',
  hover = true,
  type = 'primary',
  renderIcon = null,
  size = 'default',
  iconClassName = '',
  disabled = false,
  underlined = true,
  bold = false,
  dataTest = '',
  ...rest
}) => {
  const newButtonsEnabled = useThemeFlag('newButtons');
  return newButtonsEnabled ? (
    <a
      data-test={dataTest}
      {...rest}
      className={classNames(
        generateLinkClassName(hover, size, disabled, underlined, bold),
        rest.className
      )}
    >
      {renderIcon && <span className={iconClassName}>{renderIcon()}</span>}
      {children}
    </a>
  ) : (
    <a
      data-test={dataTest}
      {...rest}
      className={classNames(
        generateClassName(theme, hover, type),
        rest.className
      )}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'dark',
    'light',
    'light_underline',
    'beige'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  hover: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node,
  iconClassName: PropTypes.string,
  renderIcon: PropTypes.func,
  disabled: PropTypes.bool,
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
  dataTest: PropTypes.string
};

export default Link;

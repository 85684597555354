import { createContext, useContext } from 'react';
import { useSoyuzBrandConfig, useSoyuzLocales } from '@spotahome/soyuz/client';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { setDefaultOptions } from 'date-fns';
import { enUS, de, es, fr, it, pt } from 'date-fns/locale';
import once from 'lodash/once';

import SpotahomeTheme from './themes/base';

import { generateFonts } from './fonts';

export const SPOTAHOME_THEME = 'spotahome';
export const ERASMUSU_THEME = 'erasmusu';

const localeModuleMap = {
  en: enUS,
  de,
  fr,
  es,
  it,
  pt
};

const setDefaultLocale = once(currentLocale => {
  const localeModule = localeModuleMap[currentLocale] ?? enUS;
  setDefaultOptions({ locale: localeModule });
});

export const ThemeContext = createContext({
  theme: SPOTAHOME_THEME,
  flags: {
    newButtons: false
  }
});

export const useTheme = () => useContext(ThemeContext);

export const useThemeFlag = flagName => {
  const themeContext = useTheme();

  return themeContext.flags[flagName];
};

const formatCamelCaseToDashes = key =>
  key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);

const generateCSSVarSet = (valueSet, prefix) =>
  Object.entries(valueSet)
    .map(
      ([name, value]) =>
        `--${prefix}-${formatCamelCaseToDashes(name)}: ${value};`
    )
    .join('\n');

const ThemeProvider = ({
  flags = {
    newButtons: false
  },
  children
}) => {
  const brandConfig = useSoyuzBrandConfig();
  const { current: currentLocale } = useSoyuzLocales();

  const themeName = brandConfig?.id || SPOTAHOME_THEME;
  const themeToUse = merge({}, SpotahomeTheme, brandConfig.theme);

  setDefaultLocale(currentLocale);

  return (
    <ThemeContext.Provider value={{ theme: themeName, flags, themeToUse }}>
      <style>{generateFonts(brandConfig)}</style>
      <style>
        {`:root {
            ${generateCSSVarSet(themeToUse.Colors, 'sah')}
            ${generateCSSVarSet(themeToUse.Fonts, 'sah-font')}
            ${generateCSSVarSet(themeToUse.LineHeights, 'sah-line-height')}
            ${generateCSSVarSet(themeToUse.FontWeights, 'sah-font-w')}
            ${generateCSSVarSet(themeToUse.ChatButton, 'sah-chat-button')}
          }`}
      </style>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  // Useful prop for rebranding adoption. We should stop using it as soon as we complete the process
  flags: PropTypes.shape({
    newButtons: PropTypes.bool
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ThemeProvider;

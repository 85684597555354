import { lazy, Suspense } from 'react';
import NoSSR from 'react-no-ssr';

const LoginSignupModal = lazy(() => import('./LoginSignupModal'));

const LazyLoginSignUpModal = props => (
  <NoSSR>
    <Suspense fallback={null}>
      <LoginSignupModal {...props} />
    </Suspense>
  </NoSSR>
);

export default LazyLoginSignUpModal;

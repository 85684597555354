import { trans, getPagePath } from '@spotahome/soyuz/client';

import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';
import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const SpotahomeFooter = () => {
  const spotahomeLinks = [
    {
      url: getPagePath('how-it-works'),
      transKey: 'footer.item.how_it_works'
    },
    {
      url: getPagePath('about-us'),
      transKey: 'footer.item.about_us'
    },
    {
      url: getPagePath('externals.blog'),
      transKey: 'footer.item.blog'
    },
    {
      url: getPagePath('postdoctorate-grants'),
      transKey: 'footer.item.postdoctorate-grants'
    }
  ];
  return (
    <FooterSimplifiedBlock>
      <FooterSimplifiedBlockTitle text="Spotahome" />
      <FooterSimplifiedBlockList>
        {spotahomeLinks
          .filter(({ url }) => !!url)
          .map(({ transKey, url }) => (
            <FooterSimplifiedLink key={transKey} href={url} rel="">
              {trans(transKey)}
            </FooterSimplifiedLink>
          ))}
      </FooterSimplifiedBlockList>
    </FooterSimplifiedBlock>
  );
};

export default SpotahomeFooter;
